import React from 'react';

import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import { HeroBanner } from '@src/cms-components';
import { useCallback, useEffect, useState } from 'react';
import {
  LivePreviewSelect,
  LivePreviewToggle,
} from '@shared/contentstack/live-preview/widgets/live-preview-widgets';
import { LivePreviewHeader } from '@shared/contentstack/live-preview/widgets/live-preview-widgets.styles';

const type_options = [
  { label: 'Default', value: 'default' },
  { label: 'Overhang Banner', value: 'overhang_banner' },
  { label: 'Simple Banner', value: 'simple_banner' },
  { label: 'Form Banner', value: 'form_banner' },
];

const color_options = [
  { label: 'Light', value: 'light' },
  { label: 'Dark', value: 'dark' },
];

const HeroBannerPreviewPage = (props: any) => {
  const { pageContext } = props;
  const [type, setType] = useState('default');
  const [bgColor, setBgColor] = useState('light');
  const [secColor, setSecColor] = useState('light');
  const [headline, setHeadline] = useState(true);

  const [livePreviewData, setLivePreviewData] = useState<any>({});

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'hero_banner',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;
    setLivePreviewData(response[0][0]);
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return (
    <>
      <LivePreviewHeader>
        <LivePreviewSelect
          title="Banner Types"
          options={type_options}
          setSelectedItem={setType}
        />
        <LivePreviewSelect
          title="Background Color"
          options={color_options}
          setSelectedItem={setBgColor}
        />
        <LivePreviewSelect
          title="Secondary Color"
          options={color_options}
          setSelectedItem={setSecColor}
        />
        <LivePreviewToggle label="Make Headline H1" toggleItem={setHeadline} />
      </LivePreviewHeader>

      {!!livePreviewData && (
        <HeroBanner
          {...{
            ...livePreviewData,
            type,
            banner_background_color: bgColor,
            secondary_background_color: secColor,
            make_headline_h1: headline,
          }}
        />
      )}
    </>
  );
};

export default HeroBannerPreviewPage;
